<template>
  <div class="h-full">
    <div class="bottom_space rounded py-2 h-full bg-white">
      <div class="bottom_space items-center flex justify-between p-2">
        <div>
          <span class="flex items-center" >
            <span @click="redirectToList()" class="cursor-pointer pt-1"><i class="fas fa-arrow-left h-5 w-5"></i></span>
            <div class="heading-2 text-text1 pl-3">
              {{ pageDetailObj.pageName }}
            </div>
          </span>
        </div>
        <div>
          <button v-if="IsSuperAdmin && pageDetailObj.isActive" class=" bg-error font-bold cta-text text-white py-3 px-6 rounded-3xl mr-4" @click="activeInActivePopup(false)" >Deactive Page</button>
          <button v-if="IsSuperAdmin && !pageDetailObj.isActive" class=" bg-success font-bold cta-text text-white py-3 px-6 rounded-3xl mr-4" @click="activeInActivePopup(true)">Activate Page</button>
          <!-- <button v-if="IsSuperAdmin" class=" bg-secondary font-bold cta-text text-white py-3 px-6 rounded-3xl mr-4" @click="updatePageStr()">Update Page Structure</button> -->
          <button class="list_top_card_btn bg-primary font-bold cta-text text-white py-3 px-5 rounded-3xl" @click="saveComponentData()">Save</button>
        </div>
      </div>
      <div>
        <div style="overflow-y: autos; white-space: nowrap;" class="table_containder ">
          <div>
            <div v-if="pageDetailObj.pageSectionList !== null">
              <div v-for="(data, index) in pageDetailObj.pageSectionList" :key="index" class="border border-gray1 bg-white">
                <div class="flex cursor-pointer" @click="data.isOpen = !data.isOpen">
                  <div class="w-full flex  heading-3 font-bold text-text2 px-3 py-2">
                    Section Name: <span class="heading-3 pl-2 text-text2">{{ data.sectionName }}</span>
                  </div>
                  <div>
                    <span class="cursor-pointer pr-8" v-if="data.PageSectionFiledList.length > 0">
                      <topArrowIconSvg v-if="data.isOpen === true" :class="'mt-3'" :width="'20px'" :height="'20px'" />
                      <downArrowIconSvg v-if="data.isOpen === false" :class="'mt-3'" :width="'20px'" :height="'20px'" />
                    </span>
                  </div>
                </div>
                <div v-if="data.isOpen === true" class="dividerLight"></div>
                <div v-if="data.isOpen === true && data.PageSectionFiledList.length > 0" class="p-2">
                  <div class="flex grid grid-cols-12">
                      <div v-if="data.PageSectionFiledList.length > 0" class="box col-span-12">
                        <!-- <draggable :list="data.PageSectionFiledList" group="people" @start="drag = true" @end="dragArray" id='list'> -->
                          <div class="mx-2 heading-4 text-text2 border-gray-200 border rounded-lg my-3 px-1" v-for="(childrenData, indexData) in data.PageSectionFiledList" :key="indexData" v-show="childrenData.isDeleted === false">
                            <div class="heading-4 text-text2 items-center" v-if="childrenData.componentMasterId === 0">
                              <div class="flex items-center">
                                <p class=" leading-10" style="width:265px">
                                  <span class="ml-4" >
                                    {{childrenData.fieldDisplayName}}
                                  </span>
                                </p>
                                <div class="absolute right-16 cursor-pointer" @click="removeElement(indexData, index, childrenData, data)">
                                  <i class="fas fa-trash-alt text-error h-4 w-4"></i>
                                </div>
                              </div>
                              <Editor v-model="childrenData.fieldDataDetail" class="heading-4 text-text2 h-auto w-2/4 ml-2 pl-2 my-2" v-if="childrenData.fieldDataType !== 'string' && childrenData.fieldDataType !== 'integer' && childrenData.fieldDataType !== 'boolean'" style="margin-top:4px;"></Editor>
                              <textarea v-model="childrenData.fieldDataDetail" v-if="childrenData.fieldDataType === 'string'" class="border border-gray-600 rounded heading-4 text-text2 h-48 w-2/4 ml-2 pl-2 my-2"></textarea>
                              <input v-model="childrenData.fieldDataDetail" type="number" v-if="childrenData.fieldDataType === 'integer'" class="border border-gray-600 rounded heading-4 text-text2 h-9 w-40 text-right ml-2 pr-2 my-2">
                              <span v-if="childrenData.fieldDataType === 'boolean'" class="my-2">
                                <button :class="childrenData.fieldDataDetail === 'true' ? 'border-2 border-primary text-primary' : 'border border-gray3 text-black'" class=" font-bold cta-text py-2 px-3 rounded-3xl mx-2 mb-2" @click="childrenData.fieldDataDetail = 'true'">Yes</button>
                                <button :class="childrenData.fieldDataDetail === 'false' ? 'border-2 border-error text-error' : 'border border-gray3 text-black'" class="font-bold cta-text py-2 px-3 rounded-3xl" @click="childrenData.fieldDataDetail = 'false'">No</button>
                              </span>
                            </div>
                            <div class="flex items-center" v-if="childrenData.componentMasterId > 0">
                              <p class="flex leading-10" style="width:665px">
                                <span @click="childrenData.isOpen = !childrenData.isOpen" class="cursor-pointer" v-if="childrenData.componentMasterId !== 0 && childrenData.singleComponentList.length !== 0">
                                  <rightArrowIconSvg v-if="childrenData.isOpen === false" :class="'mt-4'" :width="'10px'" :height="'10px'" />
                                  <downArrowIconSvg v-if="childrenData.isOpen === true" :class="'mt-4'" :width="'10px'" :height="'10px'" />
                                </span>
                                <span :class="childrenData.componentFieldList !== null ? 'ml-4' : ''">{{childrenData.fieldDisplayName !== '' ? childrenData.fieldDisplayName : '---'}}</span>
                              </p>
                              <div v-if="childrenData.componentMasterId > 0">
                                <span class="ml-4 heading-4 text-text2" v-if="childrenData.componentMasterId > 0">Component <span v-if="childrenData.isRepeated">(Repeatable)</span></span>
                              </div>
                              <div class="absolute right-16 cursor-pointer" @click="removeElement(indexData, index, childrenData, data)">
                                <i class="fas fa-trash-alt text-error h-4 w-4"></i>
                              </div>
                            </div>
                            <div v-if="childrenData.isRepeated === false" :class="childrenData.isOpen === true && data.isOpen === true ? 'border border-gray2 ml-8 rounded-md p-2 mb-4' : 'ml-8'">
                              <div class="heading-4 text-text2" v-for="(subChildrenData, indexChildrenData) in childrenData.singleComponentList.componentFieldList" :key="indexChildrenData" >
                                <div class="flex items-center">
                                  <p class=" leading-10" style="width:265px">
                                    <span class="ml-4" v-if="childrenData.isOpen === true && data.isOpen === true">
                                      {{subChildrenData.fieldDisplayName}}
                                    </span>
                                  </p>
                                </div>
                                <Editor v-model="subChildrenData.fieldDataDetail" class="heading-4 text-text2 h-auto w-2/4 ml-2 pl-2 my-2" v-if="childrenData.isOpen === true && subChildrenData.fieldDataType !== 'string' && subChildrenData.fieldDataType !== 'integer' && subChildrenData.fieldDataType !== 'boolean'" style="margin-top:4px;"></Editor>
                                <textarea v-model="subChildrenData.fieldDataDetail" v-if="childrenData.isOpen === true && subChildrenData.fieldDataType === 'string'" class="border border-gray-600 rounded heading-4 text-text2 h-48 w-2/4 ml-2 pl-2 my-2"></textarea>
                                <input v-model="subChildrenData.fieldDataDetail" type="number" v-if="childrenData.isOpen === true && subChildrenData.fieldDataType === 'integer'" class="border border-gray-600 rounded heading-4 text-text2 h-9 w-40 text-right ml-2 pr-2 my-2">
                                <span v-if="childrenData.isOpen === true && subChildrenData.fieldDataType === 'boolean'" class="my-2">
                                  <button :class="subChildrenData.fieldDataDetail === 'true' ? 'border-2 border-primary text-primary' : 'border border-gray3 text-black'" class=" font-bold cta-text py-2 px-3 rounded-3xl mx-2 mb-2" @click="subChildrenData.fieldDataDetail = 'true'">Yes</button>
                                  <button :class="subChildrenData.fieldDataDetail === 'false' ? 'border-2 border-error text-error' : 'border border-gray3 text-black'" class="font-bold cta-text py-2 px-3 rounded-3xl" @click="subChildrenData.fieldDataDetail = 'false'">No</button>
                                </span>
                              </div>
                            </div>
                            <div v-if="childrenData.isRepeated === true" :class="childrenData.isOpen === true && data.isOpen === true && childrenData.repeatedComponentList.length > 0 ? 'border border-gray2 ml-8 rounded-md p-2 mb-4' : 'ml-8'">
                            <draggable :list="childrenData.repeatedComponentList" group="people" @start="drag = true" @end="dragArray" id='list'>
                              <div class="heading-4 text-text2 border border-gray-400 rounded-md m-2 p-2" v-for="(subChildrenData, subChildIndex) in childrenData.repeatedComponentList" :key="subChildIndex" >
                                <div class="flex items-center">
                                  <p class="flex leading-10" style="width:665px">
                                    <span @click="subChildrenData.isOpen = !subChildrenData.isOpen" class="cursor-pointer" v-if="childrenData.isOpen === true && data.isOpen === true">
                                      <rightArrowIconSvg v-if="subChildrenData.isOpen === false" :class="'mt-4'" :width="'10px'" :height="'10px'" />
                                      <downArrowIconSvg v-if="subChildrenData.isOpen === true" :class="'mt-4'" :width="'10px'" :height="'10px'" />
                                    </span>
                                    <span class="pl-2">{{subChildrenData.componentName}}</span>
                                  </p>
                                  <!-- <p class=" leading-10" style="width:665px">
                                    <span class="ml-4 font-bold" >
                                      
                                      {{subChildrenData.componentName}}
                                    </span>
                                  </p> -->
                                  <div class="absolute right-20 cursor-pointer" @click="removeElementInside(subChildIndex, indexData, index)">
                                    <i class="fas fa-trash-alt text-error h-4 w-4"></i>
                                  </div>
                                  <div class="absolute right-14 cursor-pointer">
                                    <i class="fa-solid fa-grip-vertical text-gray3 h-4 w-4"></i>
                                  </div>
                                </div>
                                <div v-if="data.isOpen === true && childrenData.isOpen && subChildrenData.isOpen" >
                                  <div class="heading-4 text-text2" v-for="(subsubChild, subsubIndex) in subChildrenData.componentFieldList" :key="subsubIndex" >
                                    <div class="flex items-center">
                                      <p class=" leading-10" style="width:265px">
                                        <span class="ml-8">
                                          {{subsubChild.fieldDisplayName}}
                                        </span>
                                      </p>
                                    </div>
                                    <Editor v-model="subsubChild.fieldDataDetail" class="heading-4 text-text2 h-auto w-2/4 ml-2 pl-2 my-2" v-if="data.isOpen === true && subsubChild.fieldDataType !== 'string' && subsubChild.fieldDataType !== 'integer' && subsubChild.fieldDataType !== 'boolean'" style="margin-top:4px;"></Editor>
                                    <textarea v-model="subsubChild.fieldDataDetail" v-if="data.isOpen === true && subsubChild.fieldDataType === 'string'" class="border border-gray-600 rounded heading-4 text-text2 h-48 w-2/4 ml-8 pl-2 my-2"></textarea>
                                    <input v-model="subsubChild.fieldDataDetail" type="number" v-if="data.isOpen === true && subsubChild.fieldDataType === 'integer'" class="border border-gray-600 rounded heading-4 text-text2 h-9 w-40 text-right ml-8 pr-2 my-2">
                                    <span v-if="data.isOpen === true && subsubChild.fieldDataType === 'boolean'" class="pl-6 my-2">
                                      <button :class="subsubChild.fieldDataDetail === 'true' ? 'border-2 border-primary text-primary' : 'border border-gray3 text-black'" class=" font-bold cta-text py-2 px-3 rounded-3xl mx-2 mb-2" @click="subsubChild.fieldDataDetail = 'true'">Yes</button>
                                      <button :class="subsubChild.fieldDataDetail === 'false' ? 'border-2 border-error text-error' : 'border border-gray3 text-black'" class="font-bold cta-text py-2 px-3 rounded-3xl" @click="subsubChild.fieldDataDetail = 'false'">No</button>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </draggable>
                            <div class="text-center text-primary cursor-pointer my-3" v-if="childrenData.isRepeated === true" @click="cloneSelectedComp(indexData, index, childrenData)">+ Add an entry</div>
                          </div>
                        </div>
                      <!-- </draggable> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="deactivePopup" class="popup_overlay">
          <div style="width: 500px;" class="custom_dialog">
            <div class="text-text1 p-3">
              <span class="heading-3">Are You Sure ?</span>
              <p class="text-gray3 heading-4 pt-3">Are you sure want to Deactive <span class="text-primary">{{pageDetailObj.pageName}}</span> page? </p>
            </div>
            <div class="flex justify-end pr-4 bg-blue-400 p-2 mt-3">
              <button class="bg-gray-100 text-black shadow mr-2 rounded-full px-5 py-1.5" @click="deactivePopup = false">No</button>
              <button class="bg-primary shadow text-white rounded-full px-5 py-1.5" @click="pageActive()">Yes</button>
            </div>
          </div>
        </div>
        <div v-if="activePagePopup" class="popup_overlay">
          <div style="width: 500px;" class="custom_dialog">
            <div class="text-text1 p-3">
              <span class="heading-3">Are You Sure ?</span>
              <p class="text-gray3 heading-4 pt-3">Are you sure want to Active <span class="text-primary">{{pageDetailObj.pageName}}</span> page? </p>
            </div>
            <div class="flex justify-end pr-4 bg-blue-400 p-2 mt-3">
              <button class="bg-gray-100 text-black shadow mr-2 rounded-full px-5 py-1.5" @click="activePagePopup = false">No</button>
              <button class="bg-primary shadow text-white rounded-full px-5 py-1.5" @click="pageActive()">Yes</button>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>
</template>
<script>
import draggable from 'vuedraggable'
import HRMAPI from '@/View/CMS/api/HRM.js'
import downArrowIconSvg from '@/assets/images/svg/downArrowIconSvg.vue'
import rightArrowIconSvg from '@/assets/images/svg/rightArrowIconSvg.vue'
import topArrowIconSvg from '@/assets/images/svg/topArrowIconSvg.vue'
// import Pagination from '@/View/components/pagination.vue'
import Editor from '@/View/components/editor.vue'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  components: {
    downArrowIconSvg,
    draggable,
    rightArrowIconSvg,
    topArrowIconSvg,
    Editor,
  },
  mixins: [deboucneMixin],
  data () {
    return {
      pageValue: false,
      deactivePopup: false,
      activePagePopup: false,
      IsSuperAdmin: false,
      saveObj: {
        pageMasterId: 0,
        pageContentDetail: []
      },
      isPush: true,
      drag: false,
      mobileView: false,
      pageDetailObj: [],
    }
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted () {
    let Isadmin =  JSON.parse(localStorage.getItem('employeeData1'))
    this.IsSuperAdmin = Isadmin.isSuperAdmin
    this.resizeWindowHandler();
    document.title = 'Page Specific Detail'
    this.getPageDetail()
  },
  watch: {
    pageDetailObj: {
      handler () {
        console.log('Watch On:-pageDetailObj', this.pageDetailObj)
      },
      deep: true
    },
    saveObj: {
      handler () {
        console.log('Watch On:-saveObj', this.saveObj)
      },
      deep: true
    }
  },
  methods: {
    activeInActivePopup (value) {
      if (value === true) {
        this.activePagePopup = true
        this.pageValue = value
      } else if (value === false) {
        this.deactivePopup = true
        this.pageValue = value
      }
    },
    resizeWindowHandler() {
      if (window.innerWidth < 684) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
    },
    removeElementInside (subChildIndex, indexData, index) {
      console.log('indexChildrenData', subChildIndex)
      console.log('indexData', indexData)
      console.log('index', index)
      console.log('this.pageDetailObj', this.pageDetailObj)
      this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData].deletedComponentList = []
      this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData].deletedComponentList.push(this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData].repeatedComponentList[subChildIndex])
      this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData].repeatedComponentList.splice(subChildIndex , 1)
    },
    removeElement (indexData, index, childrenData, data) {
      console.log('indexData', indexData)
      console.log('index', index)
      console.log('data', data)
      console.log('this.pageDetailObj.pageSectionList[index].PageSectionFiledList', this.pageDetailObj)
      if (childrenData.componentMasterId === 0) {
        this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData].isDeleted = true
      }
      if (childrenData.componentMasterId > 0) {
        if (childrenData.repeatedComponentList === null) {
          this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData].deletedComponentList = []
          this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData].deletedComponentList.push(this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData].singleComponentList)
          childrenData.isOpen = false
          childrenData.isDeleted = true
          console.log('this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData]', this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData])
        } else {
          this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData].deletedComponentList = []
          for (let I = 0; I < this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData].repeatedComponentList.length; I++) {
            this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData].deletedComponentList.push(this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData].repeatedComponentList[I])
          }
          childrenData.isDeleted = true
          console.log('this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData]', this.pageDetailObj.pageSectionList[index].PageSectionFiledList[indexData])
        }
      }
      console.log('this.saveObj.saveObj', this.saveObj.pageContentDetail)
    },
    toTop () {
      this.$vuetify.goTo(0)
    },
    redirectToList() {
      this.$router.push({name: 'specificProject', params: {projectId: parseInt(this.$route.params.projectId)}})
    },
    getPageDetail () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      HRMAPI.getContentDetail(
        parseInt(this.$route.params.pageMasterId),
        response => {
          this.pageDetailObj = response.Data === null ? [] : response.Data
          console.log("response 123", this.pageDetailObj)
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    pageActive () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      HRMAPI.pageActiveInactive(
        parseInt(this.$route.params.pageMasterId),
        this.pageValue,
        response => {
          console.log("response 123", response.Data)
          this.activePagePopup = false
          this.deactivePopup = false
          this.$router.push({name: 'specificProject', params: {projectId: parseInt(this.$route.params.projectId)}})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    dragArray () {
      this.drag = false
    },
    cloneSelectedComp (childIndex, Index, data2) {
      console.log('childIndex', childIndex)
      console.log('Index', Index)
      console.log('data2', data2)
      console.log('this.pageDetailObj', this.pageDetailObj.pageSectionList[Index].PageSectionFiledList[childIndex].repeatedComponentList)
      if (data2.isRepeated === true) {
        let Obj = JSON.parse(JSON.stringify(data2.singleComponentList))
        data2.repeatedComponentList.push(Obj)
      }
    },
    updatePageStr () {
      this.$router.push({name: 'EditPage', params: {projectId: parseInt(this.$route.params.projectId), pageMasterId: parseInt(this.$route.params.pageMasterId)}})
    },
    saveComponentData () {
      console.log('click on save button', this.pageDetailObj)
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      HRMAPI.savepageContant(
        parseInt(this.$route.params.pageMasterId),
        this.pageDetailObj,
        response => {
          console.log("response 123", response)
          this.getPageDetail()
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
  }
}
</script>
<style scoped>
.table_containder {
  padding: 1px;
}
.category_name_col {
  /* min-width: 400px !important; */
  width: 400px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.category_name_col_treeview {
  /* min-width: 400px !important; */
  width: 800px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.parentcategory_name_col {
  /* min-width: 400px !important; */
  width: 400px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.editicon_col {
  /* min-width: 400px !important; */
  width: 400px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.list_btn {
  min-width: 77px !important;
  max-width: 77px;
}
.list_top_card_btn {
  width: 183px !important;
  margin-right: 15px !important;
}
</style>
